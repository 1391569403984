
import {Component, Vue} from 'vue-property-decorator';
import api from '@/infrastructure/api/API';
import {progress} from '@/infrastructure/script/Progress';
import {InquiriesGetRequest, InquirySummary} from '@/types/inquiry/Inquiry';
import {InquiryType} from '@/types/inquiry/InquiryType';
import StatusTag from '@/views/inquiry/InquiryListStatusTag.vue';
import SortColumnIcon from '@/components/atoms/SortColumnIcon.vue';
import {SortOrder, SortOrderRequest} from '@/types/sort/SortOrder';
import TypeCheckGroup from '@/views/inquiry/InquiryListTypeCheckGroup.vue';

@Component({
  components: {
    StatusTag,
    SortColumnIcon,
    TypeCheckGroup,
  },
  filters: {
    typeFormat(type: InquiryType): string {
      if (type === InquiryType.ToSystem) {
        return 'システムに関して';
      } else if (type === InquiryType.ToOffice) {
        return 'その他に関して';
      }
      return 'JAAIからの問い合わせ';
    },
  },
})
export default class InquiryList extends Vue {
  // data
  inquiries: InquirySummary[] = [];
  inquiresAtOrder: SortOrderRequest = {
    sort: 'InquiresAt',
    order: SortOrder.DESC,
  };
  typeOrder: SortOrderRequest = {
    sort: 'Type',
    order: SortOrder.NONE,
  };
  titleOrder: SortOrderRequest = {
    sort: 'Title',
    order: SortOrder.NONE,
  };
  statusOrder: SortOrderRequest = {
    sort: 'Status',
    order: SortOrder.DESC,
  };
  request: InquiriesGetRequest = {
    sorts: [
      this.statusOrder,
    ],
    inquiryTypes: [InquiryType.ToSystem, InquiryType.ToOffice, InquiryType.ToUser],
  };

  // computed
  get isEmpty(): boolean {
    return this.inquiries.length === 0;
  }

  // method
  async initialize(): Promise<void> {
    api.getInquiries(this.request)
      .then((response: any) => {
        this.inquiries = response;
      });
  }

  search(): void {
    this.getInquiries();
  }

  change(request: SortOrderRequest): void {
    // とりあえずソートは1つ
    this.request.sorts.filter((sort) => sort.sort !== request.sort).forEach((sort) => sort.order = SortOrder.NONE);
    if (request.order !== SortOrder.NONE) {
      this.request.sorts = [request];
    } else {
      this.request.sorts = [];
    }
    this.getInquiries();
  }

  async getInquiries(): Promise<void> {
    const getInquiries = async (): Promise<void> => {
      await api.getInquiries(this.request)
        .then((response: any) => {
          this.inquiries = response;
        });
    };

    await progress(getInquiries);
  }

  toDetail(seq: number): void {
    this.$router.push({path: `/inquiry/${seq}`}).catch(() => {
      // @ts-ignore block is empty.
    });
  }

  // lifecycle hooks
  created(): void {
    this.initialize();
  }
}
