
import {Component, Prop, Vue} from 'vue-property-decorator';
import {InquiryStatus} from '@/types/inquiry/InquiryStatus';
import {InquiryType} from '@/types/inquiry/InquiryType';

@Component({})
  export default class InquiryListStatusTag extends Vue {

    @Prop({default: InquiryStatus.Inquiry})
    status!: InquiryStatus | null;
    @Prop({default: InquiryType.ToSystem})
    type!: InquiryType | null;

    // computed
    get isAnswered(): boolean {
      return this.status === InquiryStatus.Answered;
    }

    get isCompleted(): boolean {
      return this.status === InquiryStatus.Completed;
    }

    get isUserInquiry(): boolean {
      return this.status === InquiryStatus.UserInquiry;
    }

    get isToUser(): boolean {
      return this.type === InquiryType.ToUser;
    }
  }
