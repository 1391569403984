
  import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
  import {InquiryType} from '@/types/inquiry/InquiryType';

  @Component({})
  export default class InquiryListTypeCheckGroup extends Vue {
    // prop
    @Prop()
    label!: string;
    @Prop()
    value!: InquiryType[];
    // data
    toSystem: boolean = this.hasToSystem;
    toOffice: boolean = this.hasToOffice;
    toUser: boolean = this.hasToUser;

    // computed
    get hasToSystem(): boolean {
      if (!this.value) {
        return false;
      }
      return this.value.some((v) => v === InquiryType.ToSystem);
    }

    get hasToOffice(): boolean {
      if (!this.value) {
        return false;
      }
      return this.value.some((v) => v === InquiryType.ToOffice);
    }
    get hasToUser(): boolean {
      if (!this.value) {
        return false;
      }
      return this.value.some((v) => v === InquiryType.ToUser);
    }

    // emit
    @Emit('input')
    check(): InquiryType[] {
      const types: InquiryType[] = [];
      if (this.toSystem) {
        types.push(InquiryType.ToSystem);
      }
      if (this.toOffice) {
        types.push(InquiryType.ToOffice);
      }
      if (this.toUser) {
        types.push(InquiryType.ToUser);
      }
      if (types.length === 0) {
        types.push(InquiryType.ToSystem);
        types.push(InquiryType.ToOffice);
      }
      return types;
    }
  }
